import { Box, CircularProgress } from "@mui/material"
import React from "react"

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  )
}

export default LoadingScreen
