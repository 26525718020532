import { Box, Divider, Icon, IconButton } from "@mui/material";
import React from "react";

import logo from "../assets/images/logo.png";

import CircleIcon from "@mui/icons-material/Circle";
import PrinterIcon from "@mui/icons-material/PrintOutlined";
import { usePrinter } from "../contexts/PrinterContext";
import { green, grey, orange, red, yellow } from "@mui/material/colors";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  DiscountOutlined,
  HomeOutlined,
  Inventory2Outlined,
  PointOfSaleOutlined,
  Refresh,
} from "@mui/icons-material";
import { openDrawer } from "../utils/printReceipt";

const Header = () => {
  const { status, setRefresh, printer } = usePrinter();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Box sx={{ p: 2, displayPrint: "none" }}>
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Link to={"/"}>
          <img width="100px" src={logo} alt="Frietekes logo" />
        </Link>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Icon>
            <PrinterIcon sx={{ color: grey[600] }} />
          </Icon>
          <Icon>
            <CircleIcon
              sx={
                status === "CONNECTED"
                  ? { color: green[500] }
                  : status === "WARNING"
                  ? { color: orange[500] }
                  : status === "CONNECTING"
                  ? { color: yellow[500] }
                  : { color: red[500] }
              }
            />
          </Icon>
          {status === "ERROR" && (
            <IconButton onClick={() => setRefresh((prev) => !prev)}>
              <Refresh />
            </IconButton>
          )}

          <Box
            sx={{
              display: "flex",
              p: 1,
              gap: 2,
            }}>
            <IconButton onClick={() => navigate("/")}>
              <HomeOutlined
                sx={{
                  color: pathname === "/" ? "primary.main" : grey[600],
                }}
              />
            </IconButton>

            <Divider orientation="vertical" variant="middle" flexItem />

            <IconButton onClick={() => navigate("/inventory")}>
              <Inventory2Outlined
                sx={{
                  color: pathname === "/inventory" ? "primary.main" : grey[600],
                }}
              />
            </IconButton>

            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton onClick={() => navigate("/coupons")}>
              <DiscountOutlined
                sx={{
                  color: pathname === "/coupons" ? "primary.main" : grey[600],
                }}
              />
            </IconButton>

            <Divider orientation="vertical" variant="middle" flexItem />

            <IconButton onClick={() => navigate("/settings")}>
              <SettingsIcon
                sx={{
                  color: pathname === "/settings" ? "primary.main" : grey[600],
                }}
              />
            </IconButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton onClick={() => openDrawer(printer)}>
              <PointOfSaleOutlined
                sx={{
                  color: grey[600],
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Divider />
    </Box>
  );
};

export default Header;
