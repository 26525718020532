import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth"
import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { auth } from "../utils/firebase"

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
      } else if (!user) {
        setUser(null)
      } else {
        setUser(undefined)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const login = async (data) => {
    const result = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    )
    if (result.user) {
      navigate("/")
    }
  }

  const logout = async () => {
    await signOut(auth)
    navigate("/login")
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
