import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import React, { useState } from "react"

import Logo from "../assets/images/logo.png"
import { useAuth } from "../contexts/AuthContext"

const LoginScreen = () => {
  const { login } = useAuth()

  const [data, setData] = useState({
    email: "",
    password: "",
  })

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleLogin = () => {
    login(data)
  }

  return (
    <Box
      sx={{
        height: "100vh",
        flex: "1 0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ width: "75%", height: "50%", p: 2 }}
      >
        <img
          width="200px"
          src={Logo}
          alt="Frietekes logo"
          style={{ display: "block", margin: "24px auto" }}
        />

        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="emailField">Email</InputLabel>
          <OutlinedInput
            id="emailField"
            name="email"
            label="Email"
            value={login.email}
            onChange={handleChange}
            autoComplete="email"
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="passwordField">Wachtwoord</InputLabel>
          <OutlinedInput
            id="passwordField"
            name="password"
            label="Wachtwoord"
            type="password"
            autoComplete="current-password"
            value={login.password}
            onChange={handleChange}
          />
        </FormControl>
        <Button variant="contained" fullWidth onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </Box>
  )
}

export default LoginScreen
