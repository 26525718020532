import { useSnackbar } from "notistack"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { useLocalStorage } from "../hooks/useLocalStorage"

const PrinterContext = createContext()

const STATUS_CONNECTED = "CONNECTED"
const STATUS_CONNECTING = "CONNECTING"
const STATUS_ERROR = "ERROR"
const STATUS_WARNING = "WARNING"

export const PrinterContextProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(true)

  const { enqueueSnackbar } = useSnackbar()

  const [printerConfig, setPrinterConfig] = useLocalStorage("printerConfig", {
    ip: "192.168.0.199",
    prints: 2,
  })

  const [connectionStatus, setConnectionStatus] = useState("")

  const ePosDevice = useRef()
  const printer = useRef()

  useEffect(() => {
    setConnectionStatus(STATUS_CONNECTING)

    let ePosDev = new window.epson.ePOSDevice()
    ePosDevice.current = ePosDev

    ePosDevice.current.connect(printerConfig.ip, 443, (data) => {
      if (data === "OK" || data === "SSL_CONNECT_OK") {
        ePosDevice.current.createDevice(
          "local_printer",
          ePosDevice.current.DEVICE_TYPE_PRINTER,
          { crypto: true, buffer: false },
          (devObj, retCode) => {
            if (retCode === "OK") {
              printer.current = devObj
              // Start monitoring events
              printer.current.startMonitor()

              printer.current.oncoveropen = function () {
                console.log("Cover is open!")
                enqueueSnackbar("Printer cover is open!", {
                  variant: "warning",
                })

                setConnectionStatus(STATUS_WARNING)
              }

              printer.current.onpapernearend = function () {
                console.log("Papier is bijna op!")
                enqueueSnackbar("Papier is bijna op!", { variant: "info" })
                setConnectionStatus(STATUS_WARNING)
              }

              printer.current.onpaperend = function () {
                console.log("Papier is op. Vul papier bij!")
                enqueueSnackbar("Papier is op. Vul papier bij!", {
                  variant: "error",
                })
                setConnectionStatus(STATUS_WARNING)
              }

              printer.current.onstatuschange = function (status) {
                // Dit is de status dat de printer succesvol verbonden is
                if (status === 251658262) {
                  enqueueSnackbar("Printer is succesvol verbonden.", {
                    variant: "success",
                  })
                  setConnectionStatus(STATUS_CONNECTED)
                }
              }
            } else {
              enqueueSnackbar("Printer niet verbonden.", {
                variant: "error",
              })
              setConnectionStatus(STATUS_ERROR)
            }
          }
        )
      } else {
        enqueueSnackbar("Printer niet verbonden.", {
          variant: "error",
        })
        setConnectionStatus(STATUS_ERROR)
        throw data
      }
    })

    return () => {
      if (printer && printer.current) {
        // Stop monitoring events
        printer.current.stopMonitor()
      }
      if (ePosDevice && ePosDevice.current) {
        ePosDevice.current.disconnect()
      }
    }
  }, [enqueueSnackbar, printerConfig, refresh])

  const value = {
    ePosDevice,
    printer,
    status: connectionStatus,
    setRefresh,
    prints: printerConfig.prints,
    printerConfig: printerConfig,
    setPrinterConfig: setPrinterConfig,
  }

  return (
    <PrinterContext.Provider value={value}>{children}</PrinterContext.Provider>
  )
}

export const usePrinter = () => useContext(PrinterContext)
