import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { updateDoc } from "firebase/firestore";
import * as moment from "moment";
import React, { useEffect, useRef } from "react";
import { usePrinter } from "../contexts/PrinterContext";
import { printReceipt } from "../utils/printReceipt";
import audioFile from "../assets/audio/alarm.wav";

// import { v4 as uuidv4 } from "uuid";

// import voucherCode from "voucher-code-generator";
// import { db } from "../utils/firebase";

const standardMinutes = [30, 45, 60, 90];

const ConfirmDialog = ({ open, orders, imgContext, showPrintAlert }) => {
  const currentOrder = orders[0];
  const { printer, prints } = usePrinter();

  const timerRef = useRef();

  useEffect(() => {
    if (orders.length) {
      const audio = new Audio(audioFile);
      audio.play();

      timerRef.current = setInterval(() => {
        audio.play().catch((err) => {
          console.log("Fout in het afspelen van het geluid: ", err);
        });
      }, 10000);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [orders]);

  const handleConfirm = async () => {
    clearInterval(timerRef.current);
    const currentOrd = currentOrder;

    // const uniqueCode = voucherCode.generate({
    //   pattern: "####-####-####",
    //   charset: "abcdefghjklmnpqrstuvwxyz123456789",
    // });

    for (let index = 0; index < prints; index++) {
      printReceipt(
        printer,
        currentOrd,
        imgContext,
        showPrintAlert,
        currentOrd.time
      );
    }

    await updateDoc(currentOrd.ref, {
      status: "confirmed",
      etd: currentOrd.time,
      // uniqueCode: uniqueCode[0],
    });

    // await setDoc(doc(db, "tombola", uniqueCode[0]), {
    //   orderId: currentOrd.id,
    //   fullName: `${currentOrd.firstName} ${currentOrd.lastName}`,
    //   email: currentOrd.email,
    //   date: currentOrd.created,
    //   isVerified: false,
    // });
  };

  const handleConfirmMinutes = async (m) => {
    clearInterval(timerRef.current);
    const currentOrd = currentOrder;

    // const uniqueCode = voucherCode.generate({
    //   pattern: "####-####-####",
    //   charset: "abcdefghjklmnpqrstuvwxyz123456789",
    // });

    const today = moment();
    const etd = today.add({ minutes: m }).format("H:mm").toString();

    await updateDoc(currentOrd.ref, {
      status: "confirmed",
      etd: etd,
      // uniqueCode: uniqueCode[0],
    });

    for (let index = 0; index < prints; index++) {
      printReceipt(printer, currentOrd, imgContext, showPrintAlert, etd);
    }

    // await setDoc(doc(db, "tombola", uniqueCode[0]), {
    //   orderId: currentOrd.id,
    //   fullName: `${currentOrd.firstName} ${currentOrd.lastName}`,
    //   email: currentOrd.email,
    //   date: currentOrd.created,
    //   isVerified: false,
    // });
  };

  if (!currentOrder) {
    return null;
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      {currentOrder.delivery ? (
        <DialogTitle>Nieuwe bestelling!</DialogTitle>
      ) : (
        <DialogTitle sx={{ textAlign: "center", fontSize: 42 }}>
          AFHAAL
        </DialogTitle>
      )}

      <DialogContent>
        <Typography>
          {currentOrder.firstName} {currentOrder.lastName}
        </Typography>
        {currentOrder.delivery && (
          <>
            <Typography>
              {currentOrder.street} {currentOrder.number}
            </Typography>
            <Typography>
              {currentOrder.postalCode} {currentOrder.city}
            </Typography>
          </>
        )}
        <Typography>{currentOrder.telephone}</Typography>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        {currentOrder.time === "asap" ? (
          standardMinutes.map((m) => (
            <Button
              variant="contained"
              key={m}
              color="primary"
              sx={{
                width: "150px",
                height: "100px",
                fontSize: 36,
              }}
              onClick={() => handleConfirmMinutes(m)}>
              {m}
            </Button>
          ))
        ) : (
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{
              width: "150px",
              height: "100px",
              fontSize: 36,
            }}
            onClick={handleConfirm}>
            {currentOrder.time}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
