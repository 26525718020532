import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F9B11C",
      dark: "#F9B11C",
    },
    secondary: {
      main: "#1A1A1A",
    },
  },
  typography: {
    fontWeightLighter: 200,
    fontWeightBlack: 900,
    body3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: "1rem",
      lineHeight: 2,
      letterSpacing: "0.00938em",
      display: "block",
    },
  },
})
