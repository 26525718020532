import { Box, Icon, IconButton, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import React from "react"

import LocalShippingIcon from "@mui/icons-material/LocalShippingOutlined"
import ShoppingBagIcon from "@mui/icons-material/ShoppingBagOutlined"
import PrintIcon from "@mui/icons-material/PrintOutlined"
import CardIcon from "@mui/icons-material/CreditCardOutlined"
import EuroIcon from "@mui/icons-material/EuroOutlined"
import { usePrinter } from "../contexts/PrinterContext"
import { printReceipt } from "../utils/printReceipt"

const OrderItem = ({ order, imgContext, showPrintAlert }) => {
  const { printer, status } = usePrinter()

  const handlePrint = () => {
    printReceipt(printer, order, imgContext, showPrintAlert)
  }

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 5,
        borderColor: grey[500],
        borderWidth: "2px",
        borderStyle: "solid",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          color: grey[600],
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {order.time === "asap" ? `ZSM (${order.etd})` : order.time}
        </Typography>
        <Icon>
          {order.delivery ? <LocalShippingIcon /> : <ShoppingBagIcon />}
        </Icon>
      </Box>

      <Typography variant="h6" sx={{ fontWeight: "bold", mb: "auto" }}>
        {order.firstName} {order.lastName}
      </Typography>
      {order.delivery && (
        <>
          <Typography>
            {order.street} {order.number} {order.bus}
          </Typography>
          <Typography>
            {order.postalCode} {order.city}
          </Typography>
        </>
      )}
      <Typography sx={{ mt: 1, fontWeight: "medium" }}>
        {order.telephone}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          gap: 1,
          color: grey[600],
        }}
      >
        <Icon>{order.payment === "online" ? <CardIcon /> : <EuroIcon />}</Icon>

        <Typography fontSize={20} fontWeight="medium" sx={{ mr: "auto" }}>
          {(order.totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
        </Typography>
        {status !== "ERROR" && (
          <IconButton
            size="large"
            onClick={handlePrint}
            disabled={status === "ERROR"}
          >
            <PrintIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default OrderItem
