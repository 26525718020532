import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import LoadingScreen from "../screens/LoadingScreen"

const ProtectedRoute = () => {
  const { user } = useAuth()

  return typeof user === "undefined" ? (
    <LoadingScreen />
  ) : user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  )
}

export default ProtectedRoute
