import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import OrderItem from "../components/OrderItem";
import { useOrders } from "../contexts/OrdersContext";

const HomeScreen = () => {
  const { sortedOrders, lastSortedOrders, imgContext, showPrintAlert } =
    useOrders();

  const [sorting, setSorting] = useState("created");

  const handleSorting = (event, newSorting) => {
    setSorting(newSorting);
  };

  const totalPrice = sortedOrders.reduce(
    (acc, curr) => acc + curr.totalPrice,
    0
  );
  const totalOnline = sortedOrders
    .filter((o) => o.payment === "online")
    .reduce((acc, curr) => acc + curr.totalPrice, 0);

  const totalCash = sortedOrders
    .filter((o) => o.payment === "cash")
    .reduce((acc, curr) => acc + curr.totalPrice, 0);

  return (
    <>
      <Box sx={{ flex: 1, px: 4, userSelect: "none", displayPrint: "none" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                bgcolor: "primary.main",
                p: 4,
                color: "white",
                borderRadius: 5,
              }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bolder" }}
                align="right">
                {(totalPrice / 100).toFixed(2).replace(".", ",")}&euro;
              </Typography>
              <Typography sx={{ fontWeight: "light" }} align="right">
                TOTAAL
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                bgcolor: "primary.main",
                p: 4,
                color: "white",
                borderRadius: 5,
              }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bolder" }}
                align="right">
                {(totalOnline / 100).toFixed(2).replace(".", ",")}&euro;
              </Typography>
              <Typography sx={{ fontWeight: "light" }} align="right">
                ONLINE
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                bgcolor: "primary.main",
                p: 4,
                color: "white",
                borderRadius: 5,
              }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bolder" }}
                align="right">
                {(totalCash / 100).toFixed(2).replace(".", ",")}&euro;
              </Typography>
              <Typography sx={{ fontWeight: "light" }} align="right">
                CASH
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                bgcolor: "primary.main",
                p: 4,
                color: "white",
                borderRadius: 5,
              }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bolder" }}
                align="right">
                {sortedOrders.length}
              </Typography>
              <Typography sx={{ fontWeight: "light" }} align="right">
                AANTAL
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            my: 2,
            height: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <ToggleButtonGroup
            value={sorting}
            exclusive
            onChange={handleSorting}
            color="primary"
            size="large"
          >
            <ToggleButton value="time">
              <AccessTime />
            </ToggleButton>
            <ToggleButton value="created">
              <SortByAlpha />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box> */}
        {sorting === "time" ? (
          <Grid container spacing={2} sx={{ my: 2 }}>
            {sortedOrders.map((o) => (
              <Grid xs={12} md={4} xl={3} item key={o.id}>
                <OrderItem
                  order={o}
                  imgContext={imgContext}
                  showPrintAlert={showPrintAlert}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ my: 2 }}>
            {lastSortedOrders.map((o) => (
              <Grid xs={12} md={4} xl={3} item key={o.id}>
                <OrderItem
                  order={o}
                  imgContext={imgContext}
                  showPrintAlert={showPrintAlert}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default HomeScreen;
