import {
  Box,
  Divider,
  IconButton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../utils/firebase";
import { ArrowUpward } from "@mui/icons-material";

const InventoryScreen = () => {
  const [products, setProducts] = useState([]);
  const [enabled, setEnabled] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const lettersRef = useRef([]);
  const scrollRef = useRef(null);

  const [filterBy, setFilterBy] = useState("all");

  useEffect(() => {
    const getProducts = async () => {
      const q = query(collection(db, "products"), orderBy("name"));

      const qs = await getDocs(q);

      const products = qs.docs.map((ds) => ({
        ...ds.data(),
        id: ds.id,
      }));

      setProducts(products);

      setEnabled(
        products.reduce(
          (a, b) => ((a[b.id] = { isEnabled: b.isEnabled }), a),
          {}
        )
      );
    };

    getProducts();
  }, []);

  const changeEnabled = async (event) => {
    setEnabled({
      ...enabled,
      [event.target.name]: {
        ...enabled[event.target.name],
        isEnabled: event.target.checked,
      },
    });

    const docRef = doc(db, `products/${event.target.name}`);

    await updateDoc(docRef, {
      isEnabled: event.target.checked,
    });
  };

  const handleClickLetter = (index) => {
    lettersRef.current[index].scrollIntoView({ behavior: "smooth" });
  };

  const groupedProducts = products.reduce((acc, product) => {
    const firstLetter = product.name[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(product);
    return acc;
  }, {});

  return (
    <Box sx={{ px: 4, width: "100%" }}>
      <Typography sx={{ fontWeight: "lighter", mb: 2 }} variant="h6">
        PRODUCTEN
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <ToggleButtonGroup
          value={filterBy}
          exclusive
          onChange={(event, value) => {
            setFilterBy(value);
          }}
          color="primary">
          <ToggleButton value="all">Alle producten</ToggleButton>
          <ToggleButton value="offline">Offline producten</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Alle letters van de producten opsommen */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          p: 4,
        }}
        ref={scrollRef}>
        {Object.entries(groupedProducts).map(([letter, product], index) => (
          <Box
            key={letter}
            onClick={() => handleClickLetter(index)}
            sx={{
              cursor: "pointer",
              mr: 1,
              mb: 1,
              px: 1,
              py: 0.5,
              borderRadius: "50%",
              width: 32,
              height: 32,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {letter.toUpperCase()}
          </Box>
        ))}
      </Box>
      {/* <Box sx={{ display: "flex", gap: 4 }}>
        <TextField
          variant="outlined"
          placeholder="Zoeken"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <IconButton>
          <SearchOutlined />
        </IconButton>
      </Box> */}
      {Object.entries(groupedProducts).map(([letter, products], index) => (
        <React.Fragment key={letter}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 8,
            }}>
            <Typography
              sx={{ fontWeight: "bold", mb: 2, color: "primary.main" }}
              variant="h4"
              ref={(ref) => (lettersRef.current[index] = ref)}>
              {letter}
            </Typography>
            <IconButton
              size="large"
              onClick={() =>
                scrollRef.current?.scrollIntoView({ behavior: "smooth" })
              }>
              <ArrowUpward />
            </IconButton>
          </Box>
          <Divider />
          {products
            .filter((p) =>
              p.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .filter((p) => (filterBy === "offline" ? !p.isEnabled : true))
            .map((p) => (
              <div key={p.id} style={{ height: 50 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 16,
                    marginTop: 32,
                  }}>
                  <Typography variant="h6" fontWeight="medium" key={p.id}>
                    {p.name}
                  </Typography>
                  <Switch
                    name={p.id}
                    checked={enabled && enabled[p.id].isEnabled}
                    onChange={changeEnabled}
                    size="large"
                  />
                </div>
              </div>
            ))}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default InventoryScreen;
